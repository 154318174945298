import {  getHandler } from '@/utils/request'
import { removeToken } from '@/utils/auth'

export default {
  state: {
    userInfo: {} // 个人信息
  },
  getters: {
    userInfo: (state) => state.userInfo
  },
  mutations: {
    setUserInfo (state, info) {
      state.userInfo = info
    }
  },
  actions: {
    loginOut ({ commit }) {
      return new Promise((resolve) => {
        getHandler("/sys/logout", {}).finally(() => {
          commit('setUserInfo', {})
          removeToken()
          // 重置路由
          location.reload()
          resolve()
        })
      })
    }
  }
}
