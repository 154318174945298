import Vue from 'vue'
import Vuex from 'vuex'
import session from './session.js'
import permission from './permission.js'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    session,
    permission
  }
})
