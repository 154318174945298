import Cookies from 'js-cookie'

const TokenKey = 'DOG_START_KEY'

export function getToken () {
  let token = Cookies.get(TokenKey)
  if (token === undefined) {
    return ''
  }
  return token
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getCookie (name) {
  return Cookies.get(window.btoa(name))
}

export function setCookie (name, token) {
  return Cookies.set(window.btoa(name), token)
}

export function removeCookie (name) {
  return Cookies.remove(window.btoa(name))
}
