<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <!--            <keep-alive>-->
      <router-view />
      <!--            </keep-alive>-->
    </transition>
  </div>
</template>
<script>
import * as auth from "@/utils/auth";
export default {
  data() {
    return {
      token: "",
    };
  },
  mounted() {
    let href = window.location.href;
    if (href.indexOf("token=") > 0) {
      let token = href.substring(href.indexOf("token=") + 6);
      localStorage.setItem("accessToken", token);
      // alert(href.split("?")[0])
      // window.location.href = href.split("?")[0];
      // return;
    }
    this.token = localStorage.getItem("accessToken");
    auth.setToken(this.token);
    localStorage.setItem("menu", "home");
    this.$store.commit("session/setToken", this.token);

  },
  created() {},
  methods: {
    
   
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "PingFang SC";
  color: #262626;
}
.main-container {
  height: 100vh;
  overflow: auto;
  position: relative;
  background: white;
  overflow-x: hidden;
}
.nav-bar {
  position: absolute;
  z-index: 5;
  top: 22rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 36rem;
  font-weight: bold;
}
.nav-bar .back-icon {
  width: 48rem;
  position: absolute;
  left: 32rem;
}
.nav-bar .title {
  width: 360rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.h-full {
  height: 100%;
}
/*flex*/
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-1 {
  flex: 1;
}
.flex-h-center {
  justify-content: center;
}

.flex-v-center {
  align-items: center;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-end {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.flex-evenly {
  justify-content: space-evenly;
}
.flex-around {
  justify-content: space-around;
}
</style>
